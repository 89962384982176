import React, { useEffect, useState, useRef } from 'react'
import 'react-chat-elements/dist/main.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { MessageList, Input } from 'react-chat-elements'
import { pure } from 'recompose'
import Cookie from './cookies'

const Chat = ({ player }) => {
  let backendUrl = 'https://xmas.miniatur-wunderland.de'
  backendUrl = 'https://xmas.miwu.land'
  const playerRef = useRef(null) 
  const inputRef = useRef(null)
  const nameInputRef = useRef(null)
  const [inputVal, setInputVal] = useState('')
  const inputValRef = useRef('')
  const [authors, setAuthors] = useState({})
  const [messages, setMessages] = useState([])
  const [latestMessage, setLatestMessage] = useState(0)
  const [userId, setUserId] = useState(0)
  const [authorName, setAuthorName] = useState('')
  const authorNameRef = useRef('')
  const userIdRef = useRef(0)
  const authorsRef = useRef({})
  const fetchMessagesInterval = useRef(null)
  const latestMessageRef = useRef(0)
  const [submitElActive, setSubmitElActive] = useState(false)

  
  const handleAuthorNameChange = e => {
    setAuthorName(e.target.value)
    authorNameRef.current = e.target.value
  }
  
  const transmitNewAuthor = async (color) => {
    const response = await fetch(backendUrl + '/chatbackend.php?a=addAuthor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: authorNameRef.current,
        color
      })
    })
    return response.json()
  }

  const setAuthor = () => {
    if (authorNameRef.current.length === 0) {
      alert('Bitte gibt einen Namen ein, der bei Deinen Nachrichten angezeigt werden soll.')
      return false
    }
    let color = [0, 0, 0]
    do {
      color[0] = Math.floor(Math.random() * 255)
      color[1] = Math.floor(Math.random() * 255)
      color[2] = Math.floor(Math.random() * 255)
    } while ((color[0] + color[1] + color[2]) > 580)
    transmitNewAuthor(color[0].toString(16) + color[1].toString(16) + color[2].toString(16)).then(response => {
      let id = parseInt(response.authorId)
      setUserId(id)
      userIdRef.current = id
      Cookie.set('userId', id)
    })
    if (nameInputRef.current)
      nameInputRef.current.clear()
  }
  
  const handleInputChange = e => {
    setInputVal(e.target.value)
    inputValRef.current = e.target.value
  }

  const handleSend = () => {
    if (inputValRef.current === '') {
      alert('Erst ne Nachricht eintippen ;)')
      return false
    }
    addMessage()
  }

  const handleEnter = () => {
    if (userIdRef.current !== 0 || userId !== 0) {
      handleSend()
    } else {
      setAuthor()
    }
  }


  const loadAuthors = async () => {
    const response = await fetch(backendUrl + '/authors.json')
    return response.json()
  }


  const fetchAuthors = () => {
    loadAuthors().then((response) => {
      setAuthors(response)
      authorsRef.current = response
    })
  }


  const loadLatestMessage = async () => {
    const response = await fetch(backendUrl + '/chatMessageIndex.dat')
    return response.text()
  }

  const checkForMessages = () => {
    loadLatestMessage().then(response => {
      if (parseInt(response) > latestMessageRef.current) {
        fetchAuthors()
        fetchMessages()
      }
    })
  }

  const loadMessages = async () => {
    const response = await fetch(backendUrl + '/chatmessages.csv')
    return response.text()
  }
  
  const fetchMessages = () => {
    loadMessages().then(response => {
      const messagesData = response.split(/\r?\n|\r/)
      let maxIndex = latestMessage
      let newMessages = []
      for (let i = 0; i < messagesData.length; i++) {
        if (messagesData[i].length < 3)
          continue
        let message = messagesData[i].substr(0, messagesData[i].length).split(',')
        let index = parseInt(message[0])
        if (index > maxIndex) {
          maxIndex = index
          let author = parseInt(message[2])
          let timestamp = parseInt(message[1])
          let commentTime = message[5] ? Math.round(parseFloat(message[5])) : null
          commentTime = commentTime ? ((Math.floor(commentTime / 60)) + ':' + (commentTime % 60 < 10 ? '0' : '') + commentTime % 60) : null
          newMessages.push({
            position: author === userIdRef.current ? 'right' : 'left',
            commentTime: message[5] ? parseFloat(message[5]) : 100,
            type: 'text',
            /*
            title: (author === userIdRef.current ? 'Ich' : (authorsRef.current.hasOwnProperty(author) ? authorsRef.current[author].name : null)) + (commentTime  ? (() => (<span id="comment-time"> - {commentTime}</span>)) : ''),
            */
            title: <div>{author === userIdRef.current ? 'Ich' : (authorsRef.current.hasOwnProperty(author) ? authorsRef.current[author].name : null)} {commentTime && (<span className="comment-time">&nbsp; {commentTime}</span>)}</div>,
            titleColor: authorsRef.current.hasOwnProperty(author) ? ('#' + authorsRef.current[author].color) : '#22aa88',
            text: message[3] ? (message[3].indexOf('"') === 0 ? message[3].substr(1, message[3].length - 2) : message[3]) : null,
            date: new Date(timestamp * 1000),
            id: index,
            // dateString: 'gerade',
          })
          /*
          setMessages([...messages, {
            position: author === userId ? 'right' : 'left',
            type: 'text',
            title: author === userId ? 'Ich' : (authors.hasOwnProperty(author) ? authors[author].name : null),
            titleColor: authors.hasOwnProperty(author) ? authors[author].color : '#22aa88',
            text: message[3] ? message[3].replace('\"', '').substr(1, message[3].length - 2) : null,
            date: Date.parse(timestamp * 1000),
            // dateString: 'gerade',
          }])
          */
        }
      }
      if (newMessages.length > 0) {
        setLatestMessage(maxIndex)
        latestMessageRef.current = maxIndex
        /*
        let sortedMessages = newMessages.concat(messages).sort((a, b) => {
          return a.commentTime > b.commentTime ? 1 : b.commentTime > a.commentTime ? -1 : a.id > b.id ? 1 : -1
        })
        setMessages(sortedMessages)
        */
        setMessages(messages.concat(newMessages))
      }
      
    })
  }

  const sendMessage = async () => {
    const response = await fetch(backendUrl + '/chatbackend.php?a=addMessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        author: userIdRef.current,
        text: inputValRef.current,
        time: playerRef.current ? playerRef.current.getState().player.currentTime : null,
      })
    })
    
    return response.json()
  }

  const addMessage = () => {
    sendMessage().then(response => { 
      fetchMessages()
      if (inputRef.current)
        inputRef.current.clear()
    })
  }

  useEffect(() => {
    fetchAuthors()
    checkForMessages()
    if (Cookie.get('userId').length > 0) {
      setUserId(parseInt(Cookie.get('userId')))
      userIdRef.current = parseInt(Cookie.get('userId'))
    }
    fetchMessagesInterval.current = setInterval(checkForMessages, 5000)
    if (!submitElActive) {
      window.addEventListener('keypress', e => {
        if (e.key === 'Enter') {
          e.stopPropagation()
          handleEnter()
        }
      })
      setSubmitElActive(true)
      
    }
    
  }, [])

  useEffect(() => () => {
    clearInterval(fetchMessagesInterval.current)
  }, [])

  useEffect(() => {
    if (player)
    playerRef.current = player
  }, [player])


  return (
    
    <Container key="chat" className="mwvt_tour-viewer_chat_wrapper">
    <Row>
      <Col>
      <MessageList
        className="mwvt_tour-viewer_chat_list text-black scrollable"
        dataSource={messages}
        toBottomHeight={'100%'}
        style={{ 
          height: 300 + 'px',
          overflow: 'scroll',
        }}
      />
      </Col>
    </Row>
    <Row>
      <Col sm={12} >
      { userId === 0 ? (
        
        <Input 
        ref={nameInputRef}
          className="mt-3" 
          placeholder="Gib Deinen Namen ein" 
          multiline={false}
          value={authorName}
          onChange={handleAuthorNameChange}
          />
          
    ) : (
      <Input
        ref={inputRef}
        className="mt-3"
        placeholder="Nachricht ..."
        value={inputVal}
        multiline={false}
        onChange={handleInputChange}
        
      />
    )}
      </Col>
      </Row><Row>
      <Col sm={12} className="text-center pt-2">
      <Button onClick={handleEnter}>{ userId === 0 ? 'Speichern' : 'Senden' }</Button>
      </Col>
    </Row>
      </Container>
      
      

    
  )
}

export default pure(Chat)
