import { useState, useRef } from 'react'
import { pure } from 'recompose'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Image from 'react-bootstrap/Image'

import Player from './player.jsx'
import Chat from './Chat.js'
// import WinterLogo from './images/wunderland-logo-weiss.png'
// import WinterLogo from './images/wunderland-logo-rgb-weihnachten.png'



function App() {
  const playerRef = useRef(null)
  const [playerState, setPlayerState] = useState(null)

  const setPlayer = ref => {
    playerRef.current = ref
    setPlayerState(ref)
  }

  return (
    <div className="App">
      <header className="App-header">
        <Container>
          <Row>
            
            <Col >
              <h1 className="display-5 text-white text-center mt-4 mb-sm-5 mb-md-2">Weihnachtsfeier 2020</h1>
            </Col>
          </Row>
        </Container>
      </header>
      <main>
      <Container className="pt-4 pb-5">
          <Row>
            <Col md={12} lg={8} xl={9} className="mt-0 mt-md-1 mt-sm-1 mt-xs-1 ml-0 mr-0 pl-1 pr-0">
              <Player setPlayerRef={ setPlayer } />

            </Col>
            <Col md={12} lg={4} xl={3} className="pt-0 pt-md-2 pt-sm-1 pt-xs-2 pl-1 pr-1 ml-0 mr-0">
              <Chat player={playerState} />
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  )
}

export default pure(App)

/*

 <Row>
            <Col className="pt-3 text-left" md={4} sm={12}>
              <Image src={WinterLogo} width={200} className="App-logo" alt="logo" />
            </Col>
            <Col md={8} sm={12} className="pt-md-4 pt-sm-5">
              <h1 className="display-2 text-primary mt-4 mb-sm-5 mb-md-2">Weihnachtsfeier 2020</h1>
            </Col>
          </Row>
*/