import React, {useRef, useEffect} from 'react';
import { Player, BigPlayButton, ControlBar, PlayToggle, ProgressControl, FullscreenToggle } from 'video-react';
// import CurrentTimeDisplay from 'video-react/lib/components/time-controls/CurrentTimeDisplay';
import { pure } from 'recompose'

export default pure(({ setPlayerRef }) => {
  const playerRefInt = useRef(null)


  useEffect(() => {
    setPlayerRef(playerRefInt.current)
  }, [])
  return (
    <Player
      playsInline
      ref={playerRefInt}
      preload="auto"
      poster="/assets/poster.png"
      src="https://media.miniatur-wunderland.de/presse.de/2016/09/26163908/petersdom.mp4">
        <BigPlayButton position="center" />
        <ControlBar autoHide={true} disableDefaultControls={true}>
            <PlayToggle order={1.1} />
            <ProgressControl order={2.1} />
            <FullscreenToggle order={7.1} />
        </ControlBar>
    </Player>
  );
}) 
